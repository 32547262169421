document.getElementById("map") &&
	ymaps.ready(function () {
		var e = new ymaps.Map("map", { center: [55.897994, 37.406947], zoom: 16 }, { searchControlProvider: "yandex#search" }),
			i = new ymaps.Placemark(
				[55.897846, 37.398662],
				{
					hintContent: "СтеклоФабрика", balloonContent: "г. Москва, Новокуркинское шоссе 23/15, район Куркино, 3 км от МКАД"
				},
				{ iconLayout: "default#image", iconImageSize: [40, 45], iconImageOffset: [-5, -38] }
			);
		e.geoObjects.add(i), e.behaviors.disable("scrollZoom"), /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && e.behaviors.disable("drag");
	}),
	document.getElementById("map2") &&
	ymaps.ready(function () {
		var e = new ymaps.Map("map2", { center: [55.897994, 37.406947], zoom: 16 }, { searchControlProvider: "yandex#search" }),
			i = new ymaps.Placemark(
				[55.897846, 37.398662],
				{
					hintContent: "СтеклоФабрика", balloonContent: "г. Москва, Новокуркинское шоссе 23/15, район Куркино, 3 км от МКАД"
				},
				{ iconLayout: "default#image", iconImageSize: [40, 45], iconImageOffset: [-5, -38] }
			);
		e.geoObjects.add(i), e.behaviors.disable("scrollZoom"), /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && e.behaviors.disable("drag");
	}),
	$(".socs__soc").click(function () {
		$(".socs__soc").removeClass("active"), $(this).addClass("active"), $(this).parent().parent().find(".sposob").val($(this).data("sposob"));
	});

//var sliderNav = new Swiper(".slider-nav", {
//	spaceBetween: 5,
//	slidesPerView: 2,
//	direction: 'vertical',
//	breakpoints: {
//		374.98: {
//			slidesPerView: 3,
//		},
//		479.98: {
//			slidesPerView: 4,
//		},
//		767.98: {
//			slidesPerView: 5,
//		},
//		991.98: {
//			slidesPerView: 7,
//			spaceBetween: 3,
//		}
//	},
//});
//var sliderFor = new Swiper(".slider-for", {

//	spaceBetween: 10,
//	//loop: true,
//	direction: 'vertical',
//	navigation: {
//		nextEl: ".swiper-button-next"
//	},
//	thumbs: {
//		swiper: sliderNav,
//	},

//	767.98: {

//	},

//});

$(".slider-for").slick({ slidesToShow: 1, slidesToScroll: 1, arrows: !1, fade: !0, asNavFor: ".slider-nav", swipe: !1, responsive: [{ breakpoint: 992, settings: { swipe: !0 } }] }),
	$(".slider-nav").slick({
		slidesToShow: 7,
		slidesToScroll: 1,
		asNavFor: ".slider-for",
		dots: !1,
		arrows: !1,
		focusOnSelect: !0,
		vertical: !0,
		responsive: [
			{ breakpoint: 991.98, settings: { slidesToShow: 3, slidesToScroll: 1, vertical: !1 } },
			{ breakpoint: 575.98, settings: { slidesToShow: 2, slidesToScroll: 1, vertical: !1 } },
		],

	});
$(".slider-for__next-button").click((function (e) {
	$(".slider-for").slick("slickNext")
}));

$(".slider-photos__for").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: !1,
	fade: !0,
	asNavFor: ".slider-photos__nav",
	swipe: !1,
	responsive: [{
		breakpoint: 992,
		settings: {
			swipe: !0
		}
	}]
}),
	$(".slider-photos__nav").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: ".slider-photos__for",
		dots: !1,
		arrows: !1,
		focusOnSelect: !0,
		vertical: !0,
		responsive: [
			{
				breakpoint: 991.98,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					vertical: !1
				}
			},
			{
				breakpoint: 575.98, settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					vertical: !1
				}
			},
		],
	});


$(".reviews-slider").slick({
	slidesToShow: 2,
	slidesToScroll: 2,
	arrows: !1,
	swipe: !1,
	dots: true,
	adaptiveHeight: true,
	responsive: [
		{ breakpoint: 767.98, settings: { slidesToShow: 1, slidesToScroll: 1, adaptiveHeight: true, swipe: true, } },
	],
});


$(".reviews-slider__next").click((function (e) {
	$(".reviews-slider").slick("slickNext")
}));
$(".reviews-slider__prev").click((function (e) {
	$(".reviews-slider").slick("slickPrev")
}));


$(".projects-sliderfor").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: !1,
	fade: !0,
	asNavFor: ".projects-slidernav",
	swipe: !1,
	responsive:
		[{ breakpoint: 992, settings: { swipe: !0 } }]
}),

	$(".projects-slidernav").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: ".projects-sliderfor",
		dots: !1,
		arrows: !1,
		focusOnSelect: !0,
		responsive:
			[{ breakpoint: 479.98, settings: { slidesToShow: 2, } }]
	});

$('.multiple-items1').slick({
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 580,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			}
		},
	],
});
$('.multiple-items2').slick({
	infinite: true,
	slidesToShow: 2,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 580,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			}
		},
	],
});